<script>
export default {
  data() {
    return {
      magazineUrl: '',
      
      loading: true,
    };
  },
  props:{
      logo: String
  },
  name: 'header',
  mounted() {
      
  }
  
}

</script>

<template>
      <nav class="navbar navbar-expand navbar-light navbar-dark" style="background-color: #004993;">
      <div class="container">
        <div class="navbar-brand">
          <img
            v-bind:src="logo"
            height="30" alt="Logo do E-Commerce Brasil">
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
</template>
<style >
.close{
  background: none;
  border: none;
  font-size: 30px;
  padding-bottom: 0;
}
.bm-overlay {
    background: none !important;
}


</style>