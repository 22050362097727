<template>
    <div>
        <div class="destaque" id="magazines"> 
            <p>Acesse todas as anteriores • destaques • matérias técnicas</p> 
        </div>
    <div class="imgs-cards-revista">
        <router-link  class="link-card-iten" :to=" '/revistaAnteriores/' + revistaSelect "  style="color: #000; text-decoration: none;" >
            <div class="img-card-iten" v-for="(revista, index) in verMaisRevistas" :key="revista.titulo" :style="{'background-image': 'url(' + revista.imgRevistaExibe + ')'}" @mouseover="revista.hover = true" @mouseleave="revista.hover = false" >
                <router-link  :to=" '/revistaAnteriores/' + revista.id "  style="color: #000; text-decoration: none;" >
                    <div class="hover-conteudo" v-if="revista.hover" @click="enviaRevista(revista, index)">
                        <div class="hover-edicao">
                            <h3>{{revista.edicao}}</h3>
                        </div>
                        <div class="hover-preview">
                            <h3>{{revista.titulo}}</h3>
                            <p>
                                {{revista.subTitulo}}
                            </p>
                        </div>
                        <div class="hover-completo-assinante">
                            <a :href="revista.linkRevista"><p>Ler revista</p></a>
                        </div>
                    </div>
                </router-link>
            </div>
        </router-link>
    </div>
        <div class="div-carrega-revista">
            <div class="div-btn-revista">
                <button @click="addMaisFotos">Carregar Edições Mais Antigas</button>
            </div>
            <span>Visualizando {{exibiRevista}} de {{this.revistas.length - 1}}</span>
        </div>
    </div>
</template>

<script>
import eventBus from '../eventBus';
import revistas from '../revistas';

export default {
   data() {
    return {
        listaRevista: [],
        exibiRevista: 12,
        revistas: revistas.slice(1),
        revistaSelect: '',
    };
  },
  computed: {
    verMaisRevistas(){ 
        return this.revistas.slice(0,this.exibiRevista);
    }
  },
  methods:{
      addMaisFotos(){
        if(this.exibiRevista < revistas.length && this.exibiRevista + 12 < this.revistas.length){
            this.exibiRevista = this.exibiRevista + 12;
        }else{
            var faltaExibir = this.exibiRevista - this.revistas.length;
            this.exibiRevista = this.exibiRevista - faltaExibir;
        }
          
        },
        enviaRevista(revista, index){
            window.scrollTo(0, 0);
            this.revistaSelect = index;
            eventBus.$emit('recebaDados', revista);
           
        }
  }
}
</script>

<style>
    .link-card-iten{
        display: contents;
    }
    .imgs-cards-revista{
        display: flex;
        width: 85%;
        margin: 0px auto;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1250px;
    }
    .imgs-cards-revista .img-card-iten {
         width: 293px;
        height: 293px;
        background-size: cover;
    }
    .img-card-iten .hover-conteudo{
        background-color: #000000c7;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 18px;
        justify-content: space-between;
    }
    .hover-edicao{
        display: flex;
        width: 68px;
        height: 25px;
        justify-content: space-around;
        align-items: center;
        background-color: #fff;
    }
    .hover-edicao h3{
        margin: 0px;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .hover-preview{
        display: flex;
        flex-direction: column;
    }
    .hover-preview h3{
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 21px;

    }
    .hover-preview p{
        color: #858585;
        font-size: 21px;
        margin: 0px;
    }
    .hover-completo-assinante{
        display: flex;
        justify-content: center;
    }
    .hover-completo-assinante a{
        color: #fff;
        text-decoration: revert;
        font-size: 14px;
        text-transform: uppercase;
    }

    
    .destaque{
        width: 100%;
        border-top: 2px solid;
        border-bottom: 2px solid;
        margin-bottom: 45px;
    }
    .destaque p{
        font-size: 35px;
        text-transform: uppercase;
        text-align: center;
        -webkit-text-stroke-width: .5px;
        font-weight: 700;
        background: linear-gradient(90deg, #000, #fff, #000);
        letter-spacing: 5px;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-repeat: no-repeat;
        background-size: 80%;
        animation: shine 5s linear infinite;
        position: relative;
        margin-bottom: 0px;
    }
    .div-carrega-revista{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
    }
    .div-btn-revista{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .div-btn-revista button{
        background-color: #fff;
        border: 1px solid;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px;
        padding: 0px 60px;
        line-height: 55px;
    }
    .div-carrega-revista span{
        margin-top: 10px;
        letter-spacing: 0.5px;
    }
    @keyframes shine {
        0% {
            background-position-x: -500%;
        }
        100% {
            background-position-x: 500%;
        }
    }
</style>