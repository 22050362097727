<template>
	<div id="materia">
		<h1 class="titulo-materia">
			{{ conteudoArtigo.titulo }}
		</h1>
		<div class="info-materia">
			<a class="img-autor">
				<img :src="conteudoArtigo.imgAutor" loading="lazy" />
			</a>
			<h4>{{ conteudoArtigo.nomeAutor }}</h4>
			<div class="coroa">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="28.236"
					height="17.428"
					viewBox="0 0 28.236 17.428"
				>
					<path
						id="Caminho_763"
						data-name="Caminho 763"
						d="M2469.26,3949.853h24.3l2.02-16.42-7.465,5.673-6.64-6.681-6.666,6.681-7.465-5.673Z"
						transform="translate(-2467.341 -3932.425)"
						fill="#b2b2b2"
					/>
				</svg>
			</div>
			<p>{{ conteudoArtigo.cargoAutor }}</p>
			<p class="temp-leitura">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24.593"
					height="24.593"
					viewBox="0 0 24.593 24.593"
				>
					<path
						id="Icon_material-access-time"
						data-name="Icon material-access-time"
						d="M15.284,3A12.3,12.3,0,1,0,27.593,15.3,12.29,12.29,0,0,0,15.284,3ZM15.3,25.133A9.837,9.837,0,1,1,25.133,15.3,9.834,9.834,0,0,1,15.3,25.133Zm.615-15.985H14.067v7.378L20.522,20.4l.922-1.512L15.911,15.6Z"
						transform="translate(-3 -3)"
					/>
				</svg>
				Tempo de leitura: {{ conteudoArtigo.tempLeitura }}min
			</p>
		</div>
		<div class="div-conteudo-artigo">
			<p
				class="conteudo-artigo"
				:class="{ conteudoBloqueado: conteudoArtigo.active }"
			>
				<span v-html="conteudoArtigo.conteudo"> </span>
			</p>
		</div>
		<NewBtnsAssinante :btnActive="conteudoArtigo.active" />
	</div>
</template>

<script>
import NewBtnsAssinante from './NewBtnsAssinante.vue'
import artigos from '../store/artigos'

export default {
	props: {
		materia: {
			type: String,
			default: 'CAPA'
		}
	},
	components: {
		NewBtnsAssinante
	},
	computed: {
		conteudoArtigo() {
			return artigos.materia.find(
				materia => materia.titulo === this.$route.params.materia
			)
		}
	}
}
</script>
<style scoped>
* {
	font-family: 'Antonio';
}
#materia {
	display: block;
	position: relative;
	width: 67%;
	display: flex;
	flex-direction: column;
}
.titulo-materia {
	font-size: 55px;
	font-weight: 500;
	text-transform: uppercase;
	margin: 0px 0px 35px 0px;
	letter-spacing: -1px;
}
.info-materia {
	display: flex;
	width: 100%;
	align-items: center;
	margin-bottom: 30px;
}
.img-autor img {
	border-radius: 45px;
	width: 34px;
	height: 34px;
}
.info-materia h4 {
	font-size: 15px;
	margin: 0px 10px;
}
.info-materia p {
	font-size: 15px;
	margin: 0px 10px;
}
.div-conteudo-artigo {
	border-bottom: 2px solid #000;
}
.contedoa-artigo {
	color: rgb(0, 0, 0);
	font-family: sans-serif;
}
.conteudoBloqueado::before {
	display: none;
	content: '';
	width: 100%;
	height: 62px;
	position: absolute;
	background: rgb(255 255 255 / 50%)
		linear-gradient(
			to bottom,
			rgba(255, 255, 255, 0) 10%,
			rgb(255 255 255 / 88%) 90%
		)
		repeat scroll 0% 0%;
	transform: translate(0%, 119%);
}
.coroa svg {
	width: 16px;
}
.info-materia p svg {
	width: 16px;
	margin-right: 8px;
}
.temp-leitura {
	display: flex;
	align-items: center;
}
.conteudo-artigo {
	font-family: 'PT Sans', sans-serif !important;
}
@media (max-width: 450px) {
	#materia {
		/* transform: translate(0,68%); */
		width: 100%;
		/* min-height: 530px; */
	}
	.div-conteudo-artigo {
		border: none;
	}
	.conteudoBloqueado::before {
		display: none;
	}
}
</style>
