<template>
<div id="container-manteiners">
  
  <div class="oferecimento">
    <h1 class="title-mainteiners">Oferecimentos</h1>
    <div class="container-logos-patrocinadores">
      <div class="patrocionio-logo" v-for="item in filterOffering" :key="item.id">
        <img class="offering-logo" :src="'https://static.ecommercebrasil.com.br/central/partners/logos/' + item.logo" :alt="item.name">
      </div>
    </div>
  </div>
  
  <div class="oferecimento">
    <h1 class="title-mainteiners">Mantenedores</h1>
    <div class="container-logos-patrocinadores">
      <div class="patrocionio-logo" v-for="item in sponsors" :key="item.id">
        <img :src="'https://static.ecommercebrasil.com.br/central/partners/logos/thumbs/' + item.logo" :alt="item.name">
      </div>
    </div>
  </div>

  <div class="oferecimento">
    <h1 class="title-mainteiners">Apoiadores</h1>
    <div class="container-logos-patrocinadores">
      <div class="patrocionio-logo" v-for="item in supports" :key="item.id">
        <img :src="'https://static.ecommercebrasil.com.br/central/partners/logos/thumbs/' + item.logo" :alt="item.name">
      </div>
    </div>
  </div>

</div>
</template>

<script>
export default {
  data() {
    return {
      offering: [],
      sponsors: [],
      supports: [],
      offeringFilter: [],
    };
  },
  mounted() {
    fetch('https://central.ecommercebrasil.com.br/api/maintainers')
      .then(res => res.json())
      .then(res => {
        this.offering = res.filter(m => m.types.includes(1));
        this.sponsors = res.filter(m => m.types.includes(2));
        this.supports = res.filter(m => m.types.includes(3));
      }
    );
  },computed: {
    filterOffering: function (){
      return this.offering.filter(i => i.name != 'E-Commerce Brasil');
    }
  }
}
</script>
<style scoped>
  #container-manteiners{
    display: flex;
    flex-direction: column;
    background-color: #f5f7f8;
    margin-top: 35px;
    padding: 70px 0px;
    margin-top: 85px;
  }
  .oferecimento{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
  }
  .container-logos-patrocinadores{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 70%;
    align-items: center;
  }
  .patrocionio-logo{
    margin: 20px;
  }
  .title-mainteiners{
    text-transform: uppercase;
    margin-bottom: 25px;
  }
  .offering-logo {
    max-width: 120px;
  }
</style>