<script>
import Footer from './Footer';
import Header from './Header';

export default {
  data() {
    return {
      magazineUrl: '',
      loading: true,
      magazineName: '',
      partnerName: '',
      userName: '',
      typeId: 0,
      acceptTerms: false,
      isTermsAccepted: false,
      sending: false,
    };
  },
  name: 'revista',
  components:{
    Footer,
    Header
  },
  mounted() {
    this.checkToken();
  },
  methods: {
    checkToken(accept = 'null') {
      const { token } = this.$route.params;

      this.sending = true;
      
      let identifier = localStorage.getItem('identifier');

      if (!identifier) {
        identifier = Math.floor(Math.random() * 1000000);
        localStorage.setItem('identifier', identifier);
      }

      fetch('/check-token.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: 'token=' + token + '&identifier=' + identifier + '&accept=' + accept,
      })
        .then(res => res.json())
        .then((res) => {
          this.sending = false;

          if (res.isNew) {
            this.acceptTerms = true;
            this.loading = false;
            return;
          }

          this.acceptTerms = false;

          if (!res.error) {
            this.magazineUrl = res.magazine;
            this.magazineName = res.magazineName;
            this.partnerName = res.partnerName;
            this.userName = res.userName;
            this.typeId = res.typeId;

            this.loading = false;
            return;
          }

          this.$swal(`Erro ao acessar revista${res.message ? ': ' + res.message : ''}.`);

          this.$router.push('/');
        })
        .catch(() => {
          this.$swal('Erro ao acessar revista.');

          this.$router.push('/');
        });
    }
  }
}
</script>

<template>
<div>
  <Header logo="/img/logo.1469916d.png"/>

  <div class="container p-2" v-if="loading">Carregando...</div>
  <div class="revista" v-else-if="acceptTerms">
    <div class="login-background col-sm-12 col-md-12 col-lg-12 col-12 d-flex align-items-center justify-content-center p-0">
      <div style="color: #ffffff;">
        <span>Por favor, aceite os termos de uso e política de privacidade para continuar.</span>
      </div>
    </div>

    <div class="container data-revista text-right">
      <iframe class="mb-3" src="https://central.ecommercebrasil.com.br/pages/politica-de-privacidade" style="width: 100%; height: 500px; border: none;"></iframe>

      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="isTermsAccepted">
        <label class="custom-control-label" for="customCheck1">Aceito os termos de uso e política de privacidade</label>
      </div>

      <fieldset class="form-group mt-2">
        <div tabindex="-1" role="group" class="bv-no-focus-ring"><button type="submit"
            class="btn btn-primary" :disabled="sending || !isTermsAccepted" @click="checkToken('true')">Enviar</button>
        </div>
      </fieldset>
    </div>
  </div>
  <div class="revista" v-else-if="!acceptTerms">
    <div class="login-background col-sm-12 col-md-12 col-lg-12 col-12">
      <div class="logo-ecommerce-brasil">
        <h1>{{ magazineName }}</h1>
        <p class="mb-2">{{ typeId != 2 ? 'Acesso exclusivo!' : 'Acesso exclusivo para assinantes!' }}</p>
        <p class="mt-0" v-if="partnerName">Esta revista é um oferecimento da {{ partnerName }} para você!</p>
        <p class="mt-0" v-if="userName && typeId != 2">{{ userName }}, você gostaria de ter a Revista E-Commerce Brasil em suas mãos?<br />
        <a class="cupon_link" target="_blank" href="https://revista.ecommercebrasil.com.br/?utm_source=revista-ecbr&utm_medium=revista&utm_campaign=revista-online" v-if="typeId != 2">Assine agora</a> com 10% de desconto com o cupom <span class="cupon_code">REVISTAIMPRESSA</span>.</p>
      </div>
    </div>
    <div class="container data-revista">
      <iframe id="issuu-revista" allowfullscreen="" allow="fullscreen" style="border:none; margin-bottom: 40px; width:100%; height:600px;" :src="magazineUrl"></iframe>
    </div>
    <Footer/>
  </div>
</div>
</template>

<style scoped>
.revista{
  display: flex;
  flex-wrap: wrap;
  /* margin-right: -15px;
  margin-left: -15px; */
  min-height: calc(100vh - 56px);
}
.login-background{
    display: flex;
    padding: 3em 0px 0px 0px;
    flex-wrap: wrap;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0%)), url("./../assets/bg-home.jpg") center center / cover no-repeat;
}
.logo-ecommerce-brasil{
    width: 100%;
    text-align: center;
}
.logo-ecommerce-brasil h1{
    color: #ffffff;
    font-weight: 900;
}
.logo-ecommerce-brasil p{
    color: #ffffff;
}
.revista-ecommerce-brasil{
    height: 60%;
    width: 100%;
    max-width: 1600px;
    margin: 0px auto;
    text-align: center;
}
.revista-ecommerce-brasil a{
    margin: 0px auto;
}
.revista-ecommerce-brasil a img{
    width: 20%;
    height: auto;
}
.pt-3{
    margin-top: 5em;
}
.data-revista{
  padding-top: 30px;
}
@media(max-width: 970px) {
    .logo-ecommerce-brasil img{
        width: 30%;
    }
    .revista-ecommerce-brasil a img{
        width: 30%;
    }
}

.cupon_link, .cupon_code {
  color: #fab900;
}
</style>
