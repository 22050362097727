<template>
    <div class="secao-dispositivos">
        <div class="phone">
            <img src="./../assets/novas_imgs/img_phone.svg" loading="lazy">
        </div>
        <div class="book">
            <img src="./../assets/novas_imgs/img_book.svg" loading="lazy">
        </div>
        <div class="computer">
            <img src="./../assets/novas_imgs/img_note.svg" loading="lazy">
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style>
    .secao-dispositivos{
        display: flex;
        width: 85%;
        margin: 125px auto;
        align-items: flex-end;
        justify-content: center;
    }
    .secao-dispositivos .phone{
        height: auto;
        position: relative;
        
        transform: translate(32%,-13%);
    }
    .secao-dispositivos .phone img{
        width: 100%;
    }
    .secao-dispositivos .book {
        height: auto;
        position: relative;
        transform: translate(10%,0%);
    }
    .secao-dispositivos .book img{
        width: 100%;
    }
    .secao-dispositivos .computer img{
        width: 100%;
    }
</style>