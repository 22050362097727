import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

import Revista from './views/Revista.vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import { ModalPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import NewHome from './views/NewHome.vue'
import Artigos from './views/NewArtigos.vue'
import NewLogin from './views/NewLogin.vue'
import OldRevista from './views/OldRevista.vue'
import NewMateria from './views/NewMateria.vue'

Vue.config.productionTip = false

const router = new VueRouter({
	mode: 'history',
	routes: [
		{ path: '/', component: NewHome },
		{ path: '/token/:token', component: Revista },
		{
			path: '/artigos',
			name: 'artigos',
			component: Artigos,
			children: [
				{
					path: ':materia',
					name: 'materia',
					props: true,
					component: NewMateria
				}
			],
			beforeEnter(to, from, next) {
				window.scrollTo(0, 0)
				if (!to.params.materia) {
					next({ name: 'materia', params: { materia: 'CAPA' } })
				} else {
					next()
				}
			}
		},
		{ path: '/login', component: NewLogin },
		{ path: '/revistaAnteriores/:revista', component: OldRevista },
		{ path: '*', redirect: '/' }
	]
})

Vue.use(VueRouter)

Vue.use(ModalPlugin)

const options = {
	confirmButtonColor: '#0062cc',
	background: '#FFF',
	imageUrl:
		'https://eventos.ecommercebrasil.com.br/wp-content/themes/imasters-eventos-theme/img/logo/ecbr-slogan-fc.svg'
}

Vue.use(VueSweetalert2, options)

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
