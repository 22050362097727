<!--
    Removido por Rafael
    Não faz sentido este tipo até que fique personalizado
-->
<!--
<template>
    <div class="capa-revista" id="home" :style="{'background-image': 'url(' + verificaBackgroundCapa + ')'}">
       <div class="capa-revista-left">
            <div class="titulo-capa-revista">
                <p class="first">{{revista.titulo}}</p>
                <span class="edicao-revista">{{revista.edicao}}</span>
            </div>
            <div class="fundo-revista"  :style="{'background-image': 'url(' + verificaBackgroundCapaRevista + ')'}">
                <div class="capa-revista">
                    <a><img :src="verificaImgCapaRevista" loading=lazy></a>
                </div>
            </div>
        </div>
        
        <div class="capa-revista-right">
            <div class="img-capa-2">
                <a><img :src="verificaImgDestaqueLeft" loading=lazy></a>
            </div>
            <div class="img-left" :style="{'background-image': 'url(' + verificaImgBackgroundLeft + ')'}">
            
            </div>
            <div class="info-assinante">
                <h2 :style="{'color': revista.colorText }" >Edição impressa exclusiva para assinantes</h2>
            </div>
        </div>
    </div>
</template>
-->
<script>
import eventBus from '../eventBus';
// import revistas from '../revistas';

export default {
    props: ['revista'],
    data(){
      return{
         id: this.$route.params.revista,
         dFBackgroundCapa: require('./../assets/imgs/genericas/backgroundCapa.png'),
         dFImgCapaRevista: require('./../assets/imgs/imgs_65/CAPA-PEQUENA_REVISTA-332x454px.png'),
         dFBackgroundCapaRevista: require('./../assets/imgs/genericas/backgroundCapaRevista.png'),
         dFImgDestaqueLeft: require('./../assets/imgs/genericas/imgDestaqueLeft.png'),
         dFImgBackgroundLeft: require('./../assets/imgs/genericas/ImgBackgroundLeft.png'),
      }
    },
    mounted(){
        eventBus.$on('recebaDados', (revista) => {
            this.revista = revista;
        });
        this.colorTextCapa = this.revista.textColor;
    },
    beforeDestroy() {
        // removing eventBus listener
        eventBus.$off('recebaDados')
    },
    computed: {
        verificaBackgroundCapa(){
            var imgMagazine = '';
            if(this.revista.backgroundCapa != ''){
                imgMagazine = this.revista.backgroundCapa;
                
            }else{
                imgMagazine = this.dFBackgroundCapa;
               
            }
            return imgMagazine;
        },
        verificaImgCapaRevista(){
            var imgMagazine = '';
            if(this.revista.imgCapaRevista != ''){
                imgMagazine = this.revista.imgCapaRevista;
               
            }else{
                imgMagazine = this.dFImgCapaRevista;
                
            }
            return imgMagazine;
        },
        verificaBackgroundCapaRevista(){
            var imgMagazine = '';
            if(this.revista.backgroundCapaRevista != ''){
                imgMagazine = this.revista.backgroundCapaRevista;
              
            }else{
                imgMagazine = this.dFBackgroundCapaRevista;
                
            }
            return imgMagazine;
        },
        verificaImgDestaqueLeft(){
            var imgMagazine = '';
            if(this.revista.imgDestaqueLeft != ''){
                imgMagazine = this.revista.imgDestaqueLeft;
               
            }else{
                imgMagazine = this.dFImgDestaqueLeft;
               
            }
            return imgMagazine;
        },
        verificaImgBackgroundLeft(){
            var imgMagazine = '';
            if(this.revista.imgBackgroundLeft != ''){
                imgMagazine = this.revista.imgBackgroundLeft;
               
            }else{
                imgMagazine = this.dFImgBackgroundLeft;
            }
            return imgMagazine;
        },
        
    }
}
</script>
<style scoped>
    .capa-revista{
        margin-top: 65px;
        background-image: url("./../assets/imgs/imgs_65/FUNDO_CAPA-1920x793px.png");
        height: 585px;
        display: flex;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .titulo-capa-revista{
        position: absolute;
        z-index: 2;
        width: 100%;
        transform: translate(61%,67%);
        min-width: 988.6px;
        line-height: 105px;
        display: flex;
        flex-wrap: wrap;
        max-width: 1267px;
    }
    .titulo-capa-revista p{
        text-transform: uppercase;
        color: #fff;
        font-size: 80px;
        margin: 0px;
        background-color: rgb(4 33 44 / 75%);
        text-align: center;
        height: 98px;
        line-height: 98px;
    }
    .titulo-capa-revista .first::after{
        content: "Revista Bimestral";
        position: absolute;
        background-color: #fff;
        font-size: 21px;
        top: -27px;
        /*right: 100px;*/
        color: #000;
        height: 25px;
        width: 144px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        transform: translate(599%,7%);
    }
    .titulo-capa-revista .edicao-revista{
        content: "Edição 65";
        position: absolute;
        background-color: #fff;
        font-size: 21px;
        color: #000;
        height: 35px;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        top: 196px;
    }
    .titulo-capa-revista .second{
        
    }
    .capa-revista .fundo-revista{
        background-image: url("./../assets/imgs/imgs_65/FUNDO_TITULO_313x442px.png");
        height: 650px;
        background-repeat: no-repeat;
        width: 75%;
        position: relative;
        max-width: 505.19px;
        z-index: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        background-size: cover;
    }
    .capa-revista .capa-revista{
        position: relative;
        background-image: initial;
        width: 245px;
        height: 315px;
        margin-right: 51px;
    }
    .capa-revista .capa-revista img{
        width: 100%;
    }
    .img-left{
        background-image: url("./../assets/imgs/imgs_65/IMG_1185X407-03.png");
        width: 100%;
        height: 55%;
        position: relative;
        top: 188px;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .capa-revista-right{
        width: 55%;
    }
    .capa-revista-left{
        width: 45%;
        display: flex;
        justify-content: flex-end;
    }
    .img-capa-2{
       position: absolute;
       transform: translate(94%,0%);
       width: 28%;
       z-index: 1;
       max-width: 425.38px;
    }
    .img-capa-2 img{
        width: 76%;
    }
    .info-assinante{
        position: relative;
        top: 211px;
        margin-left: 35px;
    }
    .info-assinante h2{
       text-transform: uppercase;
       color: #000;
       font-size: 26px;
    }
    .imprevisto{
        padding-left: 14px;
    }
    @media(max-width: 1405px){
        .titulo-capa-revista{
            min-width: auto;
            width: 75%;
            transform: translate(61%,64%);
        }
        .titulo-capa-revista .first::after{
                transform: translate(453%,7%);
        }
        .titulo-capa-revista .edicao-revista{
            
        }
         .titulo-capa-revista p{
            font-size: 63px
        }
    }
    @media(max-width: 1336px){
        .titulo-capa-revista {
            min-width: auto;
            width: 84%;
            transform: translate(61%,64%);
        }
    }
    @media(max-width: 1173px){
        .titulo-capa-revista{
            min-width: auto;
            width: 75%;
            transform: translate(53%,161%);
            height: 80px;
            line-height: 75px;
        }
        .titulo-capa-revista .first::after{
            transform: translate(343%,7%);
        }
        .titulo-capa-revista .edicao-revista{
            
        }
         .titulo-capa-revista p{
            font-size: 50px
        }
    }
    @media(max-width: 1064px){
        .titulo-capa-revista{
            min-width: auto;
            width: 66%;
            transform: translate(44%,172%);
            height: 68px;
            line-height: 59px;
        }
        .titulo-capa-revista .first::after{
            transform: translate(246%,7%);
        }
        .titulo-capa-revista .edicao-revista{
            
        }
         .titulo-capa-revista p{
            font-size: 39px
        }
    }
   @media(max-width: 947px){
        .titulo-capa-revista{
            min-width: auto;
            width: 66%;
            transform: translate(44%,172%);
            height: 68px;
            line-height: 59px;
        }
        .titulo-capa-revista .first::after{
            transform: translate(246%,7%);
        }
        .titulo-capa-revista .edicao-revista{
            
        }
         .titulo-capa-revista p{
            font-size: 39px
        }
    }
    @media(max-width: 845px){
        .titulo-capa-revista{
            min-width: auto;
            width: 66%;
            transform: translate(44%,172%);
            height: 68px;
            line-height: 59px;
        }
        .titulo-capa-revista .first::after{
            transform: translate(246%,7%);
        }
        .titulo-capa-revista .edicao-revista{
            
        }
         .titulo-capa-revista p{
            font-size: 39px
        }
    }
    @media(max-width: 775px){
     .titulo-capa-revista p{
          height: 56px;
          line-height: 59px;
           font-size: 29px;
     }
     .titulo-capa-revista .first::after {
         transform: translate(274%,22%);
         height: 22px;
         width: 100px;
         font-size: 12px;
     }
     .titulo-capa-revista .edicao-revista {
         height: 22px;
         width: 46px;
         font-size: 12px;
         top: 112px;
     }
    }
    @media(max-width: 594px){
      .titulo-capa-revista{
          min-width: auto;
          transform: translate(5%,220%);
          line-height: 46px;
          z-index: 1;
        width: 79%;
      }
      .titulo-capa-revista p{
        font-size: 35px;
        text-align: inherit;
        height: auto;
        line-height: 48px;
      }.titulo-capa-revista .third{
        width: 74%;
      }
      .capa-revista-left{
        width: 100%;
        justify-content: center;
        z-index: 1;
       
      }
      .capa-revista-right{
        width: 100%;
      }
      .capa-revista .fundo-revista {
        height: 385px;
        z-index: 0;
        width: 85%;
        transform: translate(-9%,0%);
        z-index: 0;
    }
      .capa-revista{
          width: auto;
          margin: 0px;
          flex-direction: column;
            height: 795px;
      }
      .capa-revista .capa-revista{
          top: 245px;
          margin: 0px auto;
          transform: translate(12%,40%);

      }
      .info-assinante h2{
          display: none;
      }
      .img-left{
          position: absolute;
          top: 634px;
          height: 25%;
      }
      .img-capa-2{
          transform: translate(0%,-266%);
          z-index: 0;
          right: 0px;
          display: none;
      }
      .img-capa-2 img{
          width: 100%;
      }
      .titulo-capa-revista .first::after{
          top: 256px;
          font-size: 14px;
          height: 22px;
          width: 99px;
          left: 0px;
            transform: translate(0%,-71%);
      }
      .titulo-capa-revista .edicao-revista{
          font-size: 14px;
          height: 22px;
          width: 55px;
          top: -22px;
    }
    .imprevisto{
        padding-left: 0px;
    }
     @media(max-width: 290px){
         .titulo-capa-revista p{
            font-size: 29px;
         }
     }


    
}
</style>
