<template>
    <div id="btns-assine" :class={viewBtnAssinar:btnActive}>
        <!-- <a href="#planos"><button class="assine">Assine para ver conteúdo completo</button></a>
        <router-link to="/login"><button>Já sou assinante</button></router-link> -->
    </div>
</template>
<script>
export default {
   props: ['btnActive'],
}
</script>
<style>
    .viewBtnAssinar{
        display: flex !important;
    }
    #btns-assine{
        margin-top: 40px;
        display: none;
    }
    #btns-assine button{
        border: none;
        background-color: #000;
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;
        padding: 11px 0px;
        width: 211.02px;
    }
    #btns-assine .assine{
        margin-right: 30px;
    }
    @media(max-width: 450px){
        #btns-assine {
            margin: 20px 0px;
            justify-content: center;
        }
        #btns-assine button{
            width: 147.02px;
        }
    }
</style>