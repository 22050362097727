<template>
    <div class="secao-planos" id="planos">
        <div :class="removetitlePlano">
            <h2>Gostou da revista? Não perca tempo, assine agora!</h2>
        </div>
        <div class="planos-assinatura">
            <div :class="removetitleAssinatura">
                <h2>Plano de assinatura</h2>
            </div>

            <div class="cards-planos">
                <!-- <div class="card-plano">
                    <div class="title-card-plano">
                        <h4>Mensal</h4>
                    </div>
                    <div class="info-card-plano">
                        <p class="apenas">Apenas</p>
                        <div class="valor">
                            <p class="cifrao">R$</p>
                            <p class="real">21</p>
                            <p class="centavos">,90</p>
                            <p class="mes">/mês</p>
                        </div>
                        <div class="info-pay-track">
                            <a class="bimestral">Revista <b> bimestral </b> impressa e online</a>
                            <p class="frete">Frete incluso</p>
                            <a target="_blank"
                                href="https://assine.revista.ecommercebrasil.com.br/customer/pages/a9c2772c-60f3-4464-893d-b99e8b1e973c/subscriptions/new"
                                class="assine">Assine</a>
                        </div>
                    </div>
                </div> -->
                <!--
                <div class="card-plano">
                    <div class="title-card-plano">
                        <h4>Mensal</h4>
                    </div>
                    <div class="info-card-plano">
                        <p class="apenas">Apenas</p>
                        <div class="valor">
                            <p class="cifrao">R$</p><p class="real">21</p><p class="centavos">,90</p><p class="mes">/mês</p>
                        </div>
                        <div class="info-pay-track">
                            <a class="bimestral">Revista <b> bimestral </b> impressa</a>
                            <p class="frete">Frete incluso</p>
                            <a target="_blank" href="https://assine.ecommercebrasil.com.br/checkout/cart/plan/add/3" class="assine">Assine</a>
                        </div>
                    </div>
                </div>
-->

                <div class="card-plano">
                    <div class="title-card-plano">
                        <h4>Anual</h4>
                    </div>
                    <div class="info-card-plano">
                        <p class="apenas">Apenas</p>
                        <div class="valor">
                            <p class="cifrao">R$</p>
                            <p class="real">268</p>
                            <p class="centavos">,00</p>
                            <p class="mes">/ano</p>
                        </div>
                        <div class="info-pay-track">
                            <a class="bimestral">Revista <b> bimestral </b> impressa e online</a>
                            <p class="frete">Frete incluso</p>
                            <a target="_blank"
                                href="https://assine.revista.ecommercebrasil.com.br/customer/pages/a9c2772c-60f3-4464-893d-b99e8b1e973c/subscriptions/new"
                                class="assine">Assine</a>
                        </div>
                    </div>
                </div>

            </div>

            <div class="unsubscribe">
                <p>Se você já possui uma assinatura da revista e deseja solicitar o cancelamento, por favor,<br> envie um e-mail
                    para: <a href="mailto:revista@ecommercebrasil.com.br">revista@ecommercebrasil.com.br</a></p>
            </div>


        </div>
    </div>
</template>
<script>
export default {
    props: ['tittlePlanos', 'tittleAssinatura'],
    computed: {
        removetitlePlano() {
            if (this.tittlePlanos == 'remove') {
                return 'remove-iten';
            }
            return 'title-planos';
        },
        removetitleAssinatura() {
            if (this.tittleAssinatura == 'remove') {
                return 'remove-iten';
            }
            return 'title-plano-assinatura';
        }
    }
}
</script>
<style >
.secao-planos .unsubscribe {
    display: block;
    margin-top: 15px;
    font-size: 12px;
    text-align: center;
}

.remove-iten {
    display: none;
}

* {
    font-family: "Antonio";
}

.secao-planos {
    display: flex;
    width: 100%;
    margin: 0px auto;
    flex-direction: column;
}

.secao-planos .title-planos {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    background-color: #000;
    align-items: center;
}

.secao-planos .title-planos h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    margin: 0px;
}

.secao-planos .planos-assinatura {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
}

.secao-planos .planos-assinatura .title-plano-assinatura {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
}

.secao-planos .planos-assinatura h2 {
    text-transform: uppercase;
    font-weight: bold;
    margin: 0px;
    font-size: 50px;
    font-family: "Antonio";
}

.secao-planos .planos-assinatura .cards-planos {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.secao-planos .planos-assinatura .cards-planos .card-plano {
    width: 48%;
    margin: 0px 10px;
    max-width: 206px;
}

.card-plano .title-card-plano {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    height: 30px;
}

.card-plano .title-card-plano h4 {
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
    margin: 0px;
}

.info-card-plano {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #000;
}

.info-card-plano .apenas {
    font-size: 11px;
    font-weight: bold;
    margin: 0px;
    margin-top: 10px;
}

.info-card-plano .valor {
    display: flex;
    font-weight: bold;
    align-items: flex-start;
    margin: 10px 0px;
}

.info-card-plano .valor p {
    margin: 0px;
}

.info-card-plano .valor .cifrao {
    font-size: 13px;
    margin-top: 17px;
}

.info-card-plano .valor .real {
    font-size: 27px;
}

.info-card-plano .valor .centavos {
    font-size: 13px;
    margin-top: 5px;
}

.info-card-plano .valor .mes {
    font-size: 21px;
    margin-top: 6px;
}

.info-card-plano .info-pay-track {
    display: flex;
    flex-direction: column;
    margin: 0px;
    align-items: center;
}

.info-card-plano .info-pay-track .bimestral {
    font-size: 12px;
    color: #000;
    text-decoration: auto;
}

.info-card-plano .info-pay-track .bimestral b {
    text-transform: uppercase;
}

.info-card-plano .info-pay-track .frete {
    font-size: 12px;
}

.info-card-plano .info-pay-track .assine {
    font-size: 14px;
    text-transform: uppercase;
    background-color: #000;
    padding: 10px 40px;
    width: 100%;
    text-align: center;
    display: block;
    margin-bottom: 25px;
    color: #fff;
    text-decoration: none;
}

.info-card-plano .info-pay-track .assine:hover {

    background-color: rgb(42, 94, 51);
}

@media(max-width: 950px) {
    .secao-planos .title-planos {
        height: 77px;
    }

    .secao-planos .title-planos h2 {
        font-size: 27px;
        text-align: center;
    }

    .secao-planos .planos-assinatura h2 {
        text-align: center;
    }

    .secao-planos .planos-assinatura .cards-planos {
        flex-direction: column;
    }

    .secao-planos .planos-assinatura .cards-planos .card-plano {
        width: 100%;
        margin: 20px 10px;
    }
}</style>