<template>
	<div class="caixa-mais-lidos">
		<div class="div-img-mais-lidos">
			<img :src="imagem" loading="lazy" />
		</div>
		<div class="div-conteudo-mais-lidos">
			<h4>{{ titulo }}</h4>
			<p>{{ resumo }}</p>
		</div>
	</div>
</template>
<script>
export default {
	props: ['titulo', 'resumo', 'imagem'],
	data() {
		return {
			artigos: [
				{
					titulo: 'titulo1',
					conteudo: 'conteudo'
				},
				{
					titulo: 'titulo2',
					conteudo: 'conteudo'
				}
			]
		}
	},
	methods: {
		disparaAcao() {
			this.$emit('artigoSeleciona')
		}
	}
}
</script>
<style scoped>
.caixa-mais-lidos {
	display: flex;
	justify-content: initial;
	align-items: center;
	border-bottom: 1px solid #ccc;
	padding: 11px 0px;
	cursor: pointer;
}
.div-img-mais-lidos {
	width: 30%;
	display: flex;
	margin-right: 4px;
	min-width: 110.16px;
}
.div-img-mais-lidos img {
	width: 100%;
	max-width: 98px;
	max-height: 98px;
}
.div-conteudo-mais-lidos h4 {
	font-weight: bold;
	font-size: 22px;
	margin-bottom: 1px;
}
.div-conteudo-mais-lidos p {
	font-size: 11px;
	text-transform: uppercase;
	margin: 0px;
}
</style>
