<template>
    <div id="issuu-magazine" class="container data-revista">
        <h2 class="title-issue-revista">Revista E-Commerce Brasil - {{revista.edicao}} </h2>
       <iframe id="issuu-revista" allowfullscreen="" allow="fullscreen" style="border:none; margin-bottom: 40px; width:100%; height:600px;" :src="revista.linkRevista"></iframe>
    </div>
</template>
<script>
import eventBus from '../eventBus';

export default{
    props: ['revista'],
    mounted(){
        eventBus.$on('recebaDados', (revista) => {
            this.revista = revista;
           
        });
    },
    beforeDestroy() {
        // removing eventBus listener
        eventBus.$off('recebaDados')
    },
}
</script>
<style scoped>
    .container.data-revista{
        margin-top: 200px;
        margin-bottom: 50px;
    }
    .title-issue-revista{
        text-align: center;
        margin-bottom: 37px;
    }
    @media(max-width: 540px){
        .container.data-revista{
            margin-top: 70px;
        }
    }
</style>
