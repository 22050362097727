<template>
  <div id="app">
    

    <div class="">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
<style>
  .navbar-dark .navbar-brand{
    margin: 0px auto;
  }
</style>