<script>
import NewHeader from './NewHeader';
import CapaOldMagazine from './CapaOldMagazine';
import IssueMagazine from './IssueMagazine.vue';
import NewBodyImgs from './NewBodyImgs';
import NewDispositivos from './NewDispositivos';
import NewPlanos from './NewPlanos';
import Maintainers from './Maintainers.vue';
import Footer from './Footer';

import revistas from '../revistas';


export default {
  data() {
      return {
        id: this.$route.params.revista,
        revistas: revistas,
        revista: revistas,
      };
  },
  computed: {
    selecionaRevista() {
        var revista = revistas;
        for (var k=0; k<revista.length; k++) {
          if (revista[k].id == this.id) {
            return revista[k];
          }
        }

        return false;
    },
  },
  components: {
    NewHeader,
    CapaOldMagazine,
    IssueMagazine,
    NewBodyImgs,
    NewDispositivos,
    NewPlanos,
    Maintainers,
    Footer,

  }
}
</script>

<template>
<div>
  <NewHeader />
  <CapaOldMagazine 
    :revista="selecionaRevista" 
  />
  <IssueMagazine
    :revista="selecionaRevista"
  />
  <NewBodyImgs />
  <NewDispositivos />
  <NewPlanos />
  <Maintainers />
  <Footer/>
</div>


</template>

<style scoped>
@font-face {
    font-family: "Antonio";
    src: url(./../assets/font/Antonio-Light.ttf) format("truetype");
}
*{
    font-family: "Antonio";
}
.swal2-image{
  width: 50%;
}
.home-container-div .row{
  margin: 0px !important;
}
.home-container-div{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.login-background{
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0%)), url("./../assets/bg-home.jpg") center center / cover no-repeat;
}
.navbar-brand{
  text-align: center;
  width: 100%;
}
    
.login-row {
  min-height: calc(100vh - 56px);
}

form {
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  max-width: 380px;
}

.fork-me {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
}

.login-col {
  padding: 50px;
}

.login-screen,
.login-row {
  width: 100%;
  flex-grow: 1;
}

.logo {
  margin-top: 0px;
}

.reset-password {
  padding-top: 0px;
}

.title {
  color: #000000;
  font-size: 27px;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 20px;
}

.welcome {
  color: #ffffff;
  margin-top: 5%;
  text-align: center;
}

.welcome h1 {
  font-size: 25px;
  font-weight: 900;
}
.logo-ecommerce-brasil{
  justify-content: center;
  vertical-align: middle;
  display: flex;
  padding: 20% 0%;
}
.logo-ecommerce-brasil a{
  display: flex;
}
.logo-ecommerce-brasil img{
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 95%;
}
.swal2-popup{
  padding: 3rem 1.25rem !important;
}
.swal2-title{
  color: #363535 !important;
  font-weight: 100 !important;
  
}
.swal2-styled.swal2-confirm{
  width: 35%;
}
@media (min-width: 768px) {
  .login-screen form {
    margin-top: 150px;
  }

  .logo {
    margin-top: 50px;
  }

  .welcome {
    margin-bottom: 0px;
  }
  
}
</style>
