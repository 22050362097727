<template>
  <div class="login-footer footer">
     <div class="content-footer">
        <div class="eventos-footer">
            <div class="logo-footer">
                <a>
                    <img src="./../assets/ecbr.png">
                </a>
            </div>
        </div>
        <div class="div-contato-footer">
            <p>revista@ecommercebrasil.com.br</p>
        </div>
     </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped>
.login-footer{
    padding-top: 1px;
    background-color: #202730;
    min-width: 100%;
    font-family: Work Sans, Helvetica, Arial, sans-serif;
}
.login-footer .ul-eventos-footer{
    list-style: none;
    display: flex;
    justify-content: space-between;
    width: 80%;
    height: 1em;
}
.content-footer{
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    margin: 0px auto;
    margin-top: 3em;
    flex-direction: column;
}
.eventos-footer{
    display: flex;
}
.content-footer .ul-eventos-footer li select{
    background-color: #333f4f;
    border-color: #333f4f;
    color: #ffffff;
    width: 95%;
    height: 2em
}
.logo-footer{
    width: 100%;
    text-align: center;
}
.content-footer .ul-eventos-footer li{
    flex-grow: 1;
    text-align: center;
}
.content-footer .ul-eventos-footer li select{
    border-radius: 10px;
}
.div-form-footer{
    width: 100%;
    background-color: #242d38;
    margin-top: 1em;
    border-radius: 6px;
    padding-bottom: 1em;
}
.div-form-footer h1{
    color: #ffffff;
    text-align: center;
    font-size: 25px;
    font-weight: 800;
}
.div-form-novidades{
    width: 60%;
    margin: 2em auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.div-form-novidades form{
    text-align: center;
    margin: 0px !important;
    max-width: 100%;
}
.div-form-novidades form legend{
    color: #ffffff;
    font-size: 13px;
    margin-bottom: 2em;
}
.divForm-novidadeForm-fieldset{
    display: flex;
    justify-content: center;
}
.input-novidadeForm-emailInput{
    width: 55%;
    border-color: #ffffff;
    border: none;
    border-radius: 9px;
    margin-right: -12px;
}
.submit-novidadeForm-submit{
    background-color: #05A87C;
    color: #fff;
    border: none;
    font-size: 13px;
    height: 45px;
    border-radius: 9px;
}
.div-redesSociais-footer{
    width: 30%;
    margin: 0em auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    border-bottom: 2px solid;
    border-color: #242d38;
    padding: 1pc;
    display: none;
}
.div-redesSociais-footer h3{
    text-align: center;
    font-size: 11px;
    color: #ffffff;
}
.div-redesSociais-footer .ul-redesSociais-footer{
    display: flex;
    list-style: none;
    width: 33%;
    margin: 0px auto;
    justify-content: space-between;
    padding: 0px;
    font-size: 13px;
}
.div-redesSociais-footer .ul-redesSociais-footer a{
    color: #ffffff;
}
.div-contato-footer{
    width: 30%;
    margin: 0em auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 1pc;
}
.div-contato-footer p{
    text-align: center;
    font-size: 11px;
    color: #ffffff;
    margin-bottom: 2px;
}
@media(max-width: 970px){
    .eventos-footer{
        flex-direction: column;
        flex-wrap: wrap;
    }
    .logo-footer{
        width: 100%;
        text-align: center;
    }
    .login-footer .ul-eventos-footer{
        width: 100%;
        flex-wrap: wrap;
        margin-top: 1em;
        padding: 0px;
        height: auto;
    }
    .content-footer .ul-eventos-footer li{
        flex-grow: 1;
        margin-bottom: 7px;
    }
    .div-form-novidades{
        width: 100%;
    }
    .div-redesSociais-footer{
        width: 100%;
    }
    .div-contato-footer{
        width: 100%;
    }
}
</style>
